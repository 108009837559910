import React from 'react'
import "../assets/css/footer.css";
import facebookIcon from "../assets/img/facebookIcon.svg";

const Footer = (data) => {
    const url = window.location.pathname;
    const phone = data.data.phoneNumber
    const numberIcon = data.data.phoneNumberIcon
  return (
    <div className='footer'>
        <div className='footer-icons-position'>
            {url === "/security" ? (
                <h2>
                Follow us
                </h2>
            ):(
                <h2>
                Siguenos
                </h2>
            )}
            <a href="https://www.facebook.com/profile.php?id=100065022220175" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                <img
                    src={facebookIcon}
                    alt='Facebook icon'
                />
            </a>
        </div>
        <div className='footer-content-position'>
            {url === "/security" ? (
                <h2>
                Contact us
                </h2>
            ):(
                <h2>
                Contáctanos
                </h2>
            )}
            <a href={`tel:${phone}`} style={{ textDecoration: "none" }}>
            <h1>
            {numberIcon}
            </h1>
            </a>
        </div>
    </div>
  )
}

export default Footer