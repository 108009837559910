import React, { useState } from "react";
import { useForm } from "../hooks/useForm";
import "../assets/css/contactUsSecurity.css";
import "../assets/css/Modal.css";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import checkGreen from "../assets/img/circleCheckGreen.svg";
import { Modal } from './Modal';
import { useModal } from '../hooks/useModal';
import { ModalBody, ModalHeader } from 'reactstrap';

export const ContactUsSecurity = () => {
  
  const initialForm = {
    fullName: "",
    email: "",
    phone: "",
    zip: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [correctValidation, setCorrectValidation] = useState(0);
  const date = new Date ();
  const [year, month, day] = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
  const [hour, minutes] = [date.getHours(), date.getMinutes()];
  const [isOpenModal, openModal, closeModal] = useModal(false);
  const [loading, setLoading] = useState(false);


  // Tomo valores de la query
  const utms = window.location.search.substring(1).split("&");
  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

  // Expresion regular para validar el email
  const validar_email = (email) => {
    if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)){
      return true
    } else {
      return false
    }
  }

  const validationsForm = async (form) => {
    if (!form.fullName.trim()) {
      setCorrectValidation(6);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false
    }
    if (form.phone.trim()) {
      if (form.phone.length > 8 && form.phone.length < 13){
        setCorrectValidation(0);
      } else{
        setCorrectValidation(2);
        setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
      }
    }else{
      setCorrectValidation(2);
      setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
    }
    if (form.zip.trim()) {
      if (form.zip.length > 2 && form.zip.length < 7){
        setCorrectValidation(0);
      } else{
        setCorrectValidation(5);
        setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
      }
    }else{
      setCorrectValidation(5);
      setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);
        return false
    }
    return true
};

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);
    const validation = validationsForm(formValues)

    // Verifico si el email es valido.
    const email_validado = validar_email(formValues.email)
    if (email_validado !== true){
      setCorrectValidation(3);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return false;
    }

    let lead = {
      email: formValues.email,
      status: "New",
      phone_fax: formValues.email,
      first_name: formValues.fullName,
      phone_home: formValues.phone,
      utm_term_c: utm_term,
      description: "",
      lead_source: utm_source,
      date_entered: year + "-" + month + "-" + day + " " + hour + ":" + minutes,
      phone_mobile: formValues.phone,
      utm_medium_c: utm_medium,    
      utm_source_c: utm_source,
      utm_content_c: utm_content,
      utm_campaign_c: utm_campaign,
      assigned_user_id: "b07e5025-17b5-2d8f-ce5e-61895c7f48bd",
      identification_c: "-",
      primary_address_state: "-",
      postal_code_c: formValues.zip
    };

    // Si todo esta correcto, lo envio
    if (
      validation && email_validado
    )
    {
      await axios({
        method: "POST",
        url: "processorSecurity.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.data.includes("success")) {
            openModal()
            setLoading(false);
            setTimeout(function(){
              closeModal();
            },5000);            
          }
        })
        .catch((err) => {
          setCorrectValidation(4); // "A ocurrido un error inesperado"
          setLoading(false);
          setTimeout(() => {
            setCorrectValidation(0);
          }, 4000);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      return;
    }
  };

  return (
    <div className="contact-us" id="contact-us">
      <h2>
        Start Building Your Customized Home Security System
      </h2>
      {correctValidation !== 0 ? (
        <div className="message">
          <FormMessage correctValidation={correctValidation} />
        </div>
      ):(
        <span></span>
      )
      }
      <form onSubmit={handleSubmit} className="form-contact">
        <input
          className="input-name"
          type="text"
          name="fullName"
          id="fullName"
          value={formValues.fullName}
          onChange={handleInputChange}
          placeholder="Full name"
          required
        />
        <input
          className="input-email"
          type="email"
          name="email"
          id="email"
          value={formValues.email}
          onChange={handleInputChange}
          placeholder="Email"
          required
        />
        <div className="input-phone-content">
          <input
            className="input-phone"
            type="number"
            name="phone"
            id="phone"
            value={formValues.phone}
            onChange={handleInputChange}
            placeholder="Phone"
            required
          />
          <input
            className="input-zip"
            type="number"
            name="zip"
            id="zip"
            value={formValues.zip}
            onChange={handleInputChange}
            placeholder="Zip"
            required
          />
        </div>
        <br />
        <div className="checkbox-container">
          {loading ? (
              <button type="submit" disabled>
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                ></span>
                {"      "}
                In process
              </button>
            ) : (
              <button 
              type='Submit'
              id="contactUs.button">
                Submit
              </button>
            )}
        </div>
        <div>
          <Modal isOpen={isOpenModal} closeModal={closeModal}>
              <ModalHeader style={{display: 'block', borderBottom:'#fff'}}>
                  <span style={{float: 'right'}}></span>
              </ModalHeader>
              <ModalBody>
                <div className="checkGreen">
                  <img src={checkGreen} className="checkGreen-container" alt="checkGreen"/>
                </div>
                <h1 className="thanks-title">
                    Thank you for getting in touch!
                </h1>
              <h2 className='thanks-sub-title'>
                  We will contact you soon.
              </h2>
              </ModalBody>
          </Modal>
        </div>
      </form>
    </div>
  );
};
