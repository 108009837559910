import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/img/phoneIcon.svg";

export const Floating = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  return (
    <div className="floating">
      <div className="floating-button" type="button">
        <img src={phoneIcon} alt="Phone" className="phoneIcon" />
        <a href={`tel:${phone}`}>
          <b>{numberIcon}</b>
        </a>
      </div>
    </div>
  );
};
