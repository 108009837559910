import React, { useState, useEffect } from 'react'
import "./assets/css/App.css";
import { Logo } from "./components/Logo";
import { Floating } from "./components/Floating";
import HomeSecurity from "./components/HomeSecurity.js";
import { ContactUsSecurity } from './components/ContactUsSecurity';
import { ContactUsEntertainment } from './components/ContactUsEntertainment';
import Footer from './components/Footer';
import WhatsApp from "./components/WhatsApp.js"


const App = () => {
  const url = window.location.pathname;
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {url === "/" ? (
          <>
            {
              window.location.replace('https://masterdealer.co/')
            }
          </>
        ):(
          <>
            {data !== null ? (
              <div className="fullPage">
                <div className="top-bar">
                  <Logo />
                  <Floating data={data}/>
                </div>
                <div className='home-security-position'>
                  <HomeSecurity data={data}/>
                </div>
                <div className='contact-us-position'>
                  {url === "/security" ? (
                    <ContactUsSecurity/>
                  ):(
                    <ContactUsEntertainment/>
                  )}
                </div>
                <div className='footer-position'>
                  <Footer data={data}/>
                </div>
                <WhatsApp/>
              </div>
            ):(<></>)}
          </>
        )
      }
    </>
  )
}

export default App