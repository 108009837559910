import React from 'react'
import securityVideo from "../assets/video/security.mp4"
import entertainmentVideo from "../assets/video/entertainment.mp4"
import "../assets/css/video.css"

const SalesTransition = () => {
  const url = window.location.pathname;
  return (
    <div className='video-title'>
      {url === "/security" ? (
          <video
            autoPlay
            loop
            muted
            style={{
              display: "grid",
              position: "absolute",
              width: "100%",
              left: "50%",
              top: "50%",
              height: "100%",
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              zIndex: "0",
            }}
          >
            <source src={securityVideo} type="video/mp4"/>
          </video>
      ):(
        <video
          autoPlay
          loop
          muted
          style={{
            display: "grid",
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: "0",
          }}
        >
          <source src={entertainmentVideo} type="video/mp4"/>
        </video>
      )}
    </div>
  )
}

export default SalesTransition