import React from "react";
import "../assets/css/formMessage.css";

export const FormMessage = ({ correctValidation }) => {
  const url = window.location.pathname;
  return (
    <>
      {url === "/security" ? (
        <div>
          {correctValidation === 2 ? (
            <div
              className="telefono"
              role="alert"
            >
              Invalid phone number! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 3 ? (
            <div
              className="mail"
              role="alert"
            >
              Invalid email! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 4 ? (
            <div
              className="error-post"
              role="alert"
            >
              An unexpected error has occurred. Try it again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 5 ? (
            <div
              className="zip"
              role="alert"
            >
              Invalid Zip! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 6 ? (
            <div
              className="name"
              role="alert"
            >
              Invalid Name! Fix it and try again please
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : null}
        </div>
      ):(
        <div>
          {correctValidation === 2 ? (
            <div
              className="telefono"
              role="alert"
            >
              <h3>
              Número de teléfono inválido!
              </h3>
              <h3>
              Por favor inténtelo nuevamente
              </h3>
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 3 ? (
            <div
              className="mail"
              role="alert"
            >
              <h3>
              Email Inválido!
              </h3>
              <h3>
              Por favor inténtelo nuevamente
              </h3>
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 4 ? (
            <div
              className="error-post"
              role="alert"
            >
              <h3>
              Ha ocurrido un error inesperado.
              </h3>
              <h3>
              Por favor inténtelo nuevamente
              </h3>
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 5 ? (
            <div
              className="zip"
              role="alert"
            >
              Por favor seleccione una provincia!
              <br />
              <br />
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : correctValidation === 6 ? (
            <div
              className="name"
              role="alert"
            >
              <h3>
              Nombre inválido!
              </h3>
              <h3>
              Por favor inténtelo nuevamente
              </h3>
              <i className="glyphicon glyphicon-thumbs-up"></i>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
