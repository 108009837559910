import React from "react";
import securityLogo from '../assets/img/securityLogo.svg'
import entertainmentLogo from '../assets/img/entertainmentLogo.svg'
import '../assets/css/logo.css'

export const Logo = () => {
  const url = window.location.pathname;
  return (
      <div className="logo">
        {url === "/security" ? (
          <img className="logo-security" src={securityLogo} alt="logo" />
        ):(
          <img className="logo-entertainment" src={entertainmentLogo} alt="logo" />
        )}
      </div>
  );
};

