import React from 'react'
import "../assets/css/homeSecurity.css";
import SalesTransition from './SalesTransition';

const HomeSecurity = (data) => {
  const url = window.location.pathname;
  const phone = data.data.phoneNumber
  return (
    <div className='home-sales'>
      <div>
        <SalesTransition/>
      </div>
      {url === "/security" ? (
        <div className='home-security-content'>
          <h1 className='home-security-title'>
            Home Security.
            Hassle-Free.
          </h1>
          <h1 className='home-security-sub-title'>
            Smart home security designed for you and your family.
          </h1>
          <a href={`tel:${phone}`} style={{ textDecoration: "none" }}>
              <button className='home-security-button' value="Get it now">Get it now</button>
          </a>
        </div>
      ):(
        <div className='home-entertainment-content'>
          <h1 className='home-entertainment-title'>
            TV HD + INTERNET
            </h1>
            <h1 className='home-entertainment-sub-title'>
            Contratá los packs premium y disfrutá de contenido exclusivo.
            </h1>
            <a href={`tel:${phone}`} style={{ textDecoration: "none" }}>
                <button className='home-entertainment-button' value="Get it now">¡LO QUIERO!</button>
            </a>
        </div>
      )}
        
    </div>
  )
}

export default HomeSecurity